<template>
  <div class="error">
    <div class="error-box" v-if="type === 'error'">
      <img class="error-image" src="@/assets/images/xiaoe-empty.png" />
      <div class="error-title">{{ errorMessage }}</div>
    </div>
    <div class="error-box" v-if="type === 'expired'">
      <img class="error-image" src="@/assets/images/expired_error.png" />
      <div class="error-title">
        <span>服务已到期</span><span>若需使用，请前往电脑端管理台续费</span
        ><span>https://qiwei.xiaoe-tech.com/</span>
      </div>
      <button class="copy-btn" @click="copyText">复制地址</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "errorPage",
  components: {},
  data() {
    return {
      errorMessage: "错误页面",
      type: "error",
    };
  },
  created() {
    if (this.$route.query) {
      if (this.$route.query["error"]) {
        this.errorMessage = this.$route.query["error"];
      }
      if (this.$route.query["type"]) {
        this.type = this.$route.query["type"];
      }
    }
  },
  methods: {
    copyText() {
      let textArea = document.createElement("textarea");
      textArea.value = "https://qiwei.xiaoe-tech.com";
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand("copy");
        this.$toast("复制成功");
      } catch (err) {
        this.$toast("复制失败");
      }
      document.body.removeChild(textArea);
    },
  },
};
</script>
<style lang="scss" scoped>
.error-box {
  padding-top: 3.28rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .error-image {
    width: 1.76rem;
    height: 1.76rem;
  }
  .error-title {
    padding: 0 1.2rem 0 1.18rem;
    font-size: 0.32rem;
    margin-top: 0.32rem;
    font-weight: 400;
    color: #333333;
    text-align: center;
    line-height: 0.48rem;
    display: flex;
    flex-direction: column;
  }
  .copy-btn {
    margin-top: 0.64rem;
    width: 3.28rem;
    height: 0.8rem;
    background: #1472ff;
    border-radius: 0.4rem;
    border: none;
    outline: none;
    font-size: 0.32rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 0.48rem;
  }
}
</style>
